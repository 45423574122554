<template>
  <v-container>
    <div class="d-block d-md-flex">
      <AppAside></AppAside>

      <div class="ps-5 page_content grow">
        <Filters @updateReport="report()" :filters="filters" />
        <Stats :data_loaded="data_loaded" :stats="stats" />
      </div>
    </div>
  </v-container>
</template>

<script>
import Stats from "@/components/orders/report/Stats";
import Filters from "@/components/orders/report/Filters";
export default {
  name: "ordersReport",
  data() {
    return {
      data_loaded: false,
      stats: [],
      filters: {
        start_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        end_date:  (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      },
    };
  },
  components: {
    Filters,
    Stats,
  },
  methods: {
    report() {
      this.data_loaded = false;
      this.$store
        .dispatch("public__request", {
          config: {
            url: `ordersReport?start_date=${this.filters.start_date}&end_date=${this.filters.end_date}`,
            method: "get",
          },
        })
        .then((res) => {
          this.data_loaded = true;
          this.stats = res.data.data;
          
        });
    },
  },
  mounted() {
    this.report();
  },
};
</script>
